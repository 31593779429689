import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Fade from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label } from "reactstrap";

// import

// images
import { EVENT_DETAILS } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";

const ViewEventViewDetails = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data?.uploads?.length > 1 ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getData = catchAsync(async () => {
    const res = await EVENT_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        const dataToSet = data && data[0];
        setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Preview details</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(-1)}>
                  <Link to="#">Manage Events</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Event details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <h5 className="text-white">Banner Image</h5>

              <div className="border rounded">
                <img
                  src={data?.bannerImage}
                  style={{
                    width: "100%",
                    height: 400,
                    objectFit: "contain",
                  }}
                />
              </div>
              <h5 className="text-white mt-4">Uploads</h5>

              {data?.uploads && data?.uploads?.length !== 0 ? (
                <>
                  {" "}
                  <div
                    className="preivew_images_wrap"
                    style={{ width: "100%", overflow: "hidden" }}
                  >
                    <Slider {...settings}>
                      {data?.uploads?.map((item) => {
                        return (
                          <div
                            className="image_preview"
                            key={item?._id}
                            style={{
                              width: "100%",
                              height: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item?.link || item?.thumbNail}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "auto",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <h5 className="text-white mt-4">No Uploads Found!</h5>
                  </div>
                </>
              )}

              <div className="author_form mt-5">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="flex_fields">
                      <Label for="eventname">Event Name</Label>
                      <p className="text_line">{data?.name}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">Address</Label>
                      <p className="text_line">{data?.address}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup  className="flex_fields">
                      <Label for="eventname">Start Date</Label>
                      <p className="text_line">{data?.startDate}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">End Date</Label>
                      <p className="text_line">{data?.endDate}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="flex_fields">
                      <Label for="eventname">Start Time</Label>
                      <p className="text_line">{data?.startTime}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">End Time</Label>
                      <p className="text_line">{data?.endTime}</p>
                    </FormGroup>
                  </Col>
                  {data?.description && (
                    <Col sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Description</Label>
                        <p className="text_line">{data?.description}</p>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default ViewEventViewDetails;
