import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Fade from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

// import
import { zodResolver } from "@hookform/resolvers/zod";
import { GoogleAutocomplete } from "Component/common/GoogleAutocomplete";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ADD_EDIT_EVENT, EVENT_DETAILS } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";
import { z } from "zod";
import ImageUploader from "./ImageUploader";
import ThumbNailUploader from "./ThumbNailUploader";
import Loader from "Component/common/Loader";
import Loading from "Component/common/Loading";

const schema = z.object({
  name: z
    .string({ required_error: "Title is required" })
    .min(1, "Title is required"),
  description: z.string().optional(),
  startDate: z
    .string({ required_error: "Start date is required" })
    .min(1, "Start date is required"),
  startTime: z
    .string({ required_error: "Start time is required" })
    .min(1, "Start time is required"),
  endDate: z
    .string({ required_error: "End date is required" })
    .min(1, "End date is required"),
  endTime: z
    .string({ required_error: "End time is required" })
    .min(1, "End time is required"),
  address: z
    .string({ required_error: "Address is required" })
    .min(1, { message: "Address is required" }),
  lng: z
    .number({ required_error: "Address is required" })
    .refine((data) => !isNaN(data), {
      message: "Longitude must be a numeric value",
    }),
  lat: z
    .number({ required_error: "Address is required" })
    .refine((data) => !isNaN(data), {
      message: "Latitude must be a numeric value",
    }),
});

const Addevents = () => {
  // images state
  const [images, setImages] = useState([]);
  const [thumbNailImage, setThumbNailImage] = useState();
  // loading states
  const [submitLoader, setSubmitLoader] = useState(false);
  //data
  const [data, setData] = useState();

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      address: "",
      lat: "",
      lng: "",
    },
    values: {
      name: data?.name || "",
      description: data?.description || "",
      startDate: data?.startDate || "",
      startTime: data?.startTime || "",
      endDate: data?.endDate || "",
      endTime: data?.endTime || "",
      address: data?.address || "",
      lat: data?.location?.coordinates[1] || "",
      lng: data?.location?.coordinates[0] || "",
    },
  });

  const submitHandler = catchAsync(
    async (data) => {
      if (!thumbNailImage) {
        toast.dismiss();
        return toast.error("please upload thumbNail image");
      }

      if (!images.length) {
        toast.dismiss();
        return toast.error("please upload  images");
      }

      setSubmitLoader(true);

      const body = {
        ...(id && { _id: id }),
        ...data,
        uploads: images.map((item) => ({
          link: item,
          type: "1",
          thumbNail: item,
        })),
        bannerImage: thumbNailImage,
        type: "PUBLISH",
      };

      const res = await ADD_EDIT_EVENT(body);

      checkResponse({
        res,
        showSuccess: true,
        navigate,
        navigateUrl: "/events",
        setLoader: setSubmitLoader,
      });
    },
    {
      setLoader: setSubmitLoader,
    }
  );

  const handleAddress = (address, event) => {
    setValue("address", address.address, { shouldValidate: true });
    setValue("lng", address.lng, { shouldValidate: true });
    setValue("lat", address.lat, { shouldValidate: true });
  };

  const getData = catchAsync(async () => {
    const res = await EVENT_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        const dataToSet = data && data[0];
        setThumbNailImage(dataToSet?.bannerImage);
        setImages(
          dataToSet?.uploads?.map((item) => item.link || item.thumbNail)
        );
        setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form onSubmit={handleSubmit(submitHandler)}>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Manage Events</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/events">Manage Events </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Events Detail</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <ThumbNailUploader
                setThumbNailImage={setThumbNailImage}
                thumbNailImage={thumbNailImage}
              />

              <ImageUploader setImages={setImages} images={images} />

              <div className="author_form mt-5">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="eventname">Event Name</Label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder="Event Name"
                          />
                        )}
                      />
                      {errors?.name && (
                        <p className="text-danger">{errors?.name?.message}</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="eventname">Address</Label>
                      <GoogleAutocomplete
                        address={watch("address")}
                        lat={watch("lat")}
                        lng={watch("lng")}
                        name={"address"}
                        onChange={handleAddress}
                      />
                      {errors?.address && (
                        <p className="text-danger">
                          {errors?.address?.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="startdate">Start Date</Label>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="date"
                            name="title"
                            id="startdate"
                            placeholder="Start Date"
                          />
                        )}
                      />
                      {errors?.startDate && (
                        <p className="text-danger">
                          {errors?.startDate?.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="enddate">End Date</Label>

                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="date"
                            placeholder="Start Date"
                          />
                        )}
                      />
                      {errors?.endDate && (
                        <p className="text-danger">
                          {errors?.endDate?.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="starttime">Start time</Label>

                      <Controller
                        name="startTime"
                        control={control}
                        render={({ field }) => <Input {...field} type="time" />}
                      />
                      {errors?.startTime && (
                        <p className="text-danger">
                          {errors?.startTime?.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="starttime">End time</Label>
                      <Controller
                        name="endTime"
                        control={control}
                        render={({ field }) => <Input {...field} type="time" />}
                      />
                      {errors?.endTime && (
                        <p className="text-danger">
                          {errors?.endTime?.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="Description">Add Description</Label>

                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="textarea"
                            {...field}
                            placeholder="Type here....."
                          />
                        )}
                      />
                      {errors?.description && (
                        <p className="text-danger">
                          {errors?.description?.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mt-4">
                    <Button className="export_csv w-100" style={{
                      height: 50
                    }}> 
                      {submitLoader ? <Loading fullSize={true} /> : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Addevents;
