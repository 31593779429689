import Fade from "react-reveal";
import { Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableLayout from "Component/common/TableLayout";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import dataHandler from "hooks/dataHandler";
import {
  FEEDBACK_LIST,
  MANAGE_FEEDBACK,
  MANAGE_POSTS,
  POST_LIST,
  SUPPORT_LIST,
} from "services/ApiCalls";
import TableProfile from "Component/common/TableLayout/TableProfile";
import { commonStatusOptions } from "utilities/const";
import moment from "moment";

const CustomerFeedback = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: FEEDBACK_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      startDate: "",
      endDate: "",
    },
    dependencies: ["startDate","endDate"],
  });
  const StatusOptions = [
    {
      value: 0,
      label: "Pending",
    },
    {
      value: 1,
      label: "Approved",
    },
  ];
  const col = [
    {
      head: "Image",
      accessor: "email",
      component: (item) => (
        <TableProfile image={item?.uploads[0] && item?.uploads[0]?.thumbNail} />
      ),
    },
    {
      head: "Question",
      accessor: "long_question",
      component: (item) => <>{item?.long_question}</>,
    },
    {
      head: "Feedback",
      accessor: "feedback",
    },
    {
      head: "Created At",
      accessor: "createdAt",
      component: (item) => (
        <>{moment(item?.createdAt).format("YYYY/MM/DD LT")}</>
      ),
    },
    {
      head: "Action",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(status) => {
            statusChangeHandler(
              () =>
                MANAGE_FEEDBACK({
                  _id: item?._id,
                  status: +status,
                  isDeleted: true,
                  isBlocked: true,
                }),
              index,
              "status",
              +status,
              true
            );
          }}
          statusOptionArr={StatusOptions}
          value={item?.status}
        />
      ),
    },
  ];
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Customer Feedback</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-end gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />

                    <DatePickerField
                      label="Start Date"
                      placeholder="Start Date"
                      value={body?.startDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          startDate: date && moment(date).format("YYYY/MM/DD"),
                        }))
                      }
                    />
                    <DatePickerField
                      label="End Date"
                      placeholder="End Date"
                      value={body?.endDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          endDate: date && moment(date).format("YYYY/MM/DD"),
                          // endDate: date,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              {/* <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="tab1"
            checked={state.tab == "tab1"}
            onClick={() => setState({ tab: "tab1" })}
          />
          <label for="tab1">Pending</label>

          <input
            type="radio"
            name="tab"
            id="tab2"
            checked={state.tab == "tab2"}
            onClick={() => setState({ tab: "tab2" })}
          />
          <label for="tab2">Decline</label>
        </div> */}

              {/* {state.tab == "tab1" && ( */}
              <div className="pending_user_content mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                  <TableLayout data={data} column={col} loader={loader} body={body} />
                  <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
              {/* )} */}

              {/* {state.tab == "tab2" && (
          <div className="pending_user_content mt-4">

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )} */}
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default CustomerFeedback;
