import { toast } from "react-toastify";

export const changePageToOne = (body, setBody) => {
  const newBody = { ...body, page: 1 };
  setBody((p) => newBody);
  return newBody;
};

export const changeBody = (body, setBody, key, value) => {
  const newBody = {
    ...body,
    [key]: value,
  };
  setBody((p) => newBody);
  return newBody;
};

export const capitalizedFirstAlphaBet = (value) => {
  if (!value || typeof value !== "string") return;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const removeUnderScoreAndCapitalizeFirstLetter = (value) => {
  if (!value || typeof value !== "string") return;
  const formattedString = value.replace(/_/g, " ").toLowerCase();

  return capitalizedFirstAlphaBet(formattedString);
};

export const makingOptionsFromArr = (arr) => {
  return arr.map((type) => ({
    label: removeUnderScoreAndCapitalizeFirstLetter(type), // Remove underscores and capitalize first letters
    value: type,
  }));
};

export const makingOptionsFromData = (arr, labelKey, valueKey) => {
  return arr.map((item) => ({
    label: removeUnderScoreAndCapitalizeFirstLetter(item[labelKey]), // Remove underscores and capitalize first letters
    value: item[valueKey],
  }));
};

export const removeUnderScore = (message) => {
  if (typeof message == "number" || !message) {
    return message;
  }
  if (typeof message !== "string") {
    toast.dismiss();

    return toast.warning("something went wrong!");
  }

  return message.split("_").join(" ");
};

export const removeHyphen = (value) => {
  if (!value) return "";
  return value.replace(/-/g, " ").toLowerCase();
};

export function createObjectURL(object) {
  return window.URL
    ? window.URL.createObjectURL(object)
    : window.webkitURL.createObjectURL(object);
}

export const catchAsync = (fn, otherArg) => {
  return (...arg) =>
    fn(...arg).catch((error) => {
      toast.dismiss();
      toast.error("something went wrong");
      otherArg?.setLoader && otherArg?.setLoader(false);
      otherArg?.callBack && otherArg?.callBack();
      console.log(error, "error");
    });
};

export const checkResponse = ({
  res,
  setData,
  setTotal,
  showSuccess,
  dataToSet,
  setLoader,
  totalCount,
  navigate,
  navigateUrl,
}) => {
  if (res?.status === 200) {
    setData && setData((dataToSet && dataToSet(res?.data)) || res?.data?.data);
    setTotal &&
      setTotal(
        totalCount || res?.data?.data?.totalRecord || res?.data?.total_count
      );
    showSuccess && (toast.dismiss(), toast.success(res?.data?.message));
    setLoader && setLoader(false);
    navigate && navigate(navigateUrl);
    return true;
  } else {
    toast.dismiss();
    toast.error(res?.data?.data || "Something went wrong!");
    setLoader && setLoader(false);
    return false;
  }
};

export const makeParams = (data) => {
  if (!data && typeof data !== "object") return;

  let paramString = "?";

  Object.keys(data).forEach(
    (item) => (paramString += `${item}=${data[item]}&`)
  );

  return paramString;
};

export const removeUnwantedKeys = (body) => {
  const newBody = {
    ...body,
  };

  Object.keys(newBody).forEach((item) => {
    if (!newBody[item] && newBody[item] !== 0) {
      delete newBody[item];
    }
  });

  return newBody;
};
export function truncateString(str, maxLength) {
  // If the string is longer than maxLength, truncate it and add "..."
  if (str?.length > maxLength) {
    return str?.slice(0, maxLength) + ".....";
  }
  // If it's already short enough, return the string as is
  return str;
}

export const showMessageSendReceiveTime = (createAt) => {
  const currentTime = new Date();
  const messageTime = new Date(createAt);
  const timeDiffInSeconds = (currentTime - messageTime) / 1000; // difference in seconds

  if (timeDiffInSeconds < 60) {
    const secondsAgo = Math.round(timeDiffInSeconds);
    if (secondsAgo == 0) {
      return "now";
    }
    return `${secondsAgo} seconds ago`;
  } else if (timeDiffInSeconds < 3600) {
    const minutesAgo = Math.round(timeDiffInSeconds / 60);
    return `${minutesAgo} min ago`;
  } else {
    const hours = messageTime.getHours().toString().padStart(2, "0");
    const minutes = messageTime.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
};
