import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import { ReactComponent as Editsvg } from "assets/svg/editsvg.svg";

import {
  EVENT_LIST,
  GET_ISSUES_LIST,
  GET_SUB_ADMIN_LIST,
  MANAGE_ISSUES,
  MANAGE_SUB_ADMIN,
} from "services/ApiCalls";
import Plussvg from "../../../../assets/svg/plussvg.svg";
import { truncateString } from "utilities/utilities";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import { useSelector } from "react-redux";

const substable = () => {
  const navigate = useNavigate();
  const permission = useSelector((s) => s?.profileData?.subAdminPermission);

  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: GET_ISSUES_LIST,
    dataToSet: (data) => data?.data,
  });

  const handleDelete = async (item) => {
    
    try {
      let body = {
        _id: item?._id,
        isDeleted: true,
      };
      const res = await MANAGE_ISSUES(body);
      refetch()
    } catch (error) {}
  };

  const col = [
    {
      head: "Issue Name",
      accessor: "name",
    },
    {
      head: "Craeted At",
      accessor: "createdAt",
      component: (item) => moment(item?.createdAt).format("YYYY/MM/DD HH:mm"),
    },
  ];

  if (permission != null ? permission?.isAdd : true) {
    col.push({
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <div className="d-flex justify-content-start">
            <Button
              className="btn_action me-3"
              onClick={() =>
                navigate(`/edit-support-issues/${item?._id}`, { state: item })
              }
            >
              <Editsvg />
            </Button>
            <TableActions deleteHandler={() => handleDelete(item)} />
          </div>
        </>
      ),
    });
  }

  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Support Isssues</h3>
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 justify-content-between mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-end gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />
                  </div>
                </div>
              </div>
              {(permission != null ? permission?.isAdd : true) && (
                <div className="export_btn d-flex align-items-center  justify-content-end gap-3">
                  <Button
                    className="plussvg"
                    onClick={() => navigate("/add-support-issues")}
                  >
                    <img src={Plussvg} alt="" className="img-fluid" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="manageuser_tabbingset mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default substable;
