import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// images
import Notifyicon from "../../../src/assets/svg/noti_icon.svg";
import Searchicon from "../../../src/assets/svg/search_icon.svg";
import dummyImg from "../../../src/assets/images/user.webp";

import Axios from "services/Axios";
import ConfirmationPop from "Component/Modals/ConfirmationPop";
import { catchAsync, checkResponse } from "utilities/utilities";
import { MANAGE_NOTIFICATION, NOTIFICATION_LIST } from "services/ApiCalls";
import moment from "moment";
import { useFirebase } from "../../firebase/firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { navigationPaths } from "utilities/const";

export default function Headerlogin(props) {
  const [logoutPop, setLogoutPop] = useState(false);
  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState();
  const notifyID = useSelector((s) => s?.login?.notificationId);
  const profileData = useSelector((s) => s?.profileData?.profileData);
  console.log(profileData);
  const navigate = useNavigate();
  const token = useFirebase();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const logout = () => {
    Axios.LogoutUser();
    localStorage.clear();
  };

  const getData = catchAsync(async () => {
    const res = await NOTIFICATION_LIST();
    checkResponse({
      res,
      setData: (data) => {
        setData(data);
        setTotalCount(res?.data?.total_count);
      },
    });
  });

  useEffect(() => {
    getData();
  }, [token, notifyID]);

  const handleDelete = async (id) => {
    const data = {
      status: "accept",
      isUserRead: false,
      isDeleted: true,
    };

    try {
      let res = await MANAGE_NOTIFICATION(data);
      toast.success(res?.data?.message);

      // Optionally update local state to reflect changes
      getData();
    } catch (error) {
      console.error("Error deleting notification", error);
    }
  };
  const handleReadNotify = async (item) => {
    const path = navigationPaths[item?.type];
  
    if (path) {
      navigate(path);
    } else {
 
      navigate("#"); // Replace with your desired fallback path
    }
    const data = {
      status: "accept",
      isUserRead: false,
      isDeleted: true,
      _id: item?._id,
    };

    try {
      let res = await MANAGE_NOTIFICATION(data);

      // Optionally update local state to reflect changes
      getData();
    } catch (error) {
      console.error("Error deleting notification", error);
    }
  };
  return (
    <>
      <ConfirmationPop
        type={"logout"}
        confirmHandler={logout}
        confirmation={logoutPop}
        setConfirmation={setLogoutPop}
      />
      <section className={scroll ? "header-main  header_fixed" : "header-main"}>
        <Container fluid>
          <div className="header">
            {/* <Button>
              <div className="togglebar">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Button> */}

            <div className="add-wallet-wrap d-flex">
              <div className="search_form">
                {/* <Form>
                  <FormGroup className="position-relative">
                    <Input
                      type="search"
                      name="email"
                      id="headersearch"
                      placeholder="Search here..."
                    />
                    <div className="search_icon">
                      <img src={Searchicon} alt="" className="img-fluid" />
                    </div>
                  </FormGroup>
                </Form> */}
              </div>

              <div className="notification position-relative">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <img src={Notifyicon} alt="" className="img-fluid" />
                  </DropdownToggle>

                  <DropdownMenu>
                    <div className="d-flex justify-content-between">
                      <h5 className="notify_head">Notification</h5>
                      {data?.length != 0 && (
                        <Link
                          to="#"
                          className="dark-link"
                          onClick={() => handleDelete()}
                        >
                          Read All Notification
                        </Link>
                      )}
                    </div>
                    {data?.length != 0 ? (
                      <>
                        {" "}
                        {data?.slice(0, 8)?.map((item, idx) => {
                          return (
                            <DropdownItem
                              key={idx}
                              onClick={() => handleReadNotify(item)}
                            >
                              <div className="notify_single_line d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <div className="single_notify_icon me-2 position-relative">
                                    <img
                                      src={Notifyicon}
                                      alt=""
                                      className="img-fluid"
                                    />
                                    {/* <div className="dotnotify">1</div> */}
                                  </div>
                                  <h5>{item?.title}</h5>
                                  {/* <p>{item?.message}</p> */}
                                </div>
                                <p>
                                  {moment(item?.createdAt).format("HH:MM ")}
                                </p>
                              </div>
                            </DropdownItem>
                          );
                        })}{" "}
                        <div className="text-center" >
                          <Link to="/all-notification" className="dark-link">
                            Show All Notification
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="notify_single_line d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="single_notify_icon me-2">
                              <img
                                src={Notifyicon}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <h5>No Notification !</h5>
                          </div>
                        </div>
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div className="dot">{data?.length > 0 ? totalCount : 0}</div>
              </div>
              <div className="user_login">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <div className="user_wrap">
                      <img
                        src={dummyImg}
                        alt=""
                        className="img-fluid profile-picture"
                      />
                    </div>
                    <div className="walet_name_addrees text-start">
                      <h5>{profileData?.name}</h5>
                      <p>{profileData?.role == 2 ? "SubAdmin" : "Admin"}</p>
                    </div>
                    <div className="arrowloin">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M4 6L8 10L12 6"
                          stroke="#F2F2F2"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <Link to="/profile">Profile</Link>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem>
                      <Link to="/" onClick={() => setLogoutPop(true)}>
                        Logout
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
