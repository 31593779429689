import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Fade } from "react-reveal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { catchAsync, checkResponse } from "utilities/utilities";
import {
  ADD_ISSUES,
  ADD_SUB_ADMIN,
  SUB_ADMIN_DETAILS,
} from "services/ApiCalls";
import Loading from "Component/common/Loading";

// Define your validation schema
const schema = z.object({
  subname: z.string().min(1, "Name is required"),
});

// Define your permission modules

const AddIssues = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset, // Use reset to set form values
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const { id } = useParams();
  useEffect(() => {
    if (location?.state) {
      setValue("subname", location?.state?.name);
    }
  }, [location]);

  const submitHandler = async (data) => {
    setSubmitLoader(true);
    let body = {
      name: data?.subname,
    };
    // Construct the request body

    const res = await ADD_ISSUES(id ? { ...body, _id: id } : body);

    checkResponse({
      res,
      showSuccess: true,
      navigateUrl: "/support-issues",
      navigate,
      setLoader: setSubmitLoader,
    });
  };

  console.log(data);
  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Add Support Issues</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/support-issues">Manage Support Issues</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Add Support Issues</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form">
                <Row>
                  {/* Name Input */}
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="subname">Issue Name</Label>
                      <Controller
                        name="subname"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="subname"
                            placeholder="Name"
                            {...field}
                            style={{ color: "white" }}
                          />
                        )}
                      />
                      {errors.subname && (
                        <span style={{ color: "red" }}>
                          {errors.subname.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Col lg={12} className="mt-4">
                  <Button className="export_csv w-100" style={{ height: 50 }}>
                    {submitLoader ? <Loading fullSize={true} /> : "Submit"}
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </form>
      </section>
    </Fade>
  );
};

export default AddIssues;

const eyeopen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
    />
  </svg>
);

const eyeclose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"
    />
  </svg>
);
