import dataHandler from "hooks/dataHandler";
import { useEffect, useState } from "react";
import { GrGallery } from "react-icons/gr";
import { CHAT_LIST } from "services/ApiCalls";
import io from "socket.io-client";
import { showMessageSendReceiveTime } from "utilities/utilities";
import MessageList from "./MessageList";
import SendMessageForm from "./SendMessageForm";

function ChatSupport() {
  const { loader, data, refetch } = dataHandler({
    api: CHAT_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      limit: 10000,
      orderBy: "lastMessageTime",
    },
  });

  const [supportId, setSupportId] = useState(null);
  const [supportDetails, setSupportDetails] = useState(null);
  const [socket, setSocket] = useState(null);
  const userId = localStorage?.getItem("_id");
  useEffect(() => {
    // Initialize the connection
    const socketInstance = io("https://api.jamme3na.org", {
      transports: ["websocket"],
      secure: true,
    });

    // When a connection is made
    socketInstance.on("connect", (data) => {
      console.log("Connected to server");

      socketInstance.emit("login-admin", { userId });

      console.log(socketInstance, "socket");

      setSocket(socketInstance);

      // Listen for incoming messages
    });

    // Clean up on component unmount
    return () => {
      socket?.disconnect();
    };
  }, []);

  return (
    <>
      <section className="dashboard_first comon_dashpad">
        <div className="container py-2">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-4 mb-4 mb-md-0">
              <h5 className="font-weight-bold mb-3 text-white">Member</h5>
              <div className="card mask-custom">
                <div className="card-body">
                  <ul
                    className="list_scroll list-unstyled mb-0"
                    style={{
                      height: "calc(100vh - 200px)",
                    }}
                  >
                    {data?.length !== 0 ? (
                      <>
                        {data?.map((item, idx) => {
                          return (
                            <li
                              className="p-2 border-bottom"
                              style={
                                supportId == item?._id
                                  ? {
                                      background: "antiquewhite",
                                    }
                                  : {
                                      borderBottom:
                                        "1px solid rgba(255,255,255,.3) !important",
                                    }
                              }
                              onClick={() => {
                                setSupportId((p) => item?._id);
                                setSupportDetails((p) => item);
                              }}
                            >
                              <a
                                href="#!"
                                className="d-flex justify-content-between link-light align-item-start"
                              >
                                <div className="d-flex flex-row align-item-start">
                                  <img
                                    src={item?.userId?.profileImage}
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="pt-1">
                                    <p className="fw-bold mb-0">
                                      {item?.fullName}
                                    </p>
                                    <p className="custom_font text-black m-0 w-75 text-truncate">
                                      {item.messageType === 2 ? (
                                        <GrGallery />
                                      ) : (
                                        item?.message
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="pt-1">
                                  <p className="custom_font text-black mb-1">
                                    {showMessageSendReceiveTime(
                                      item?.lastMessageTime
                                    )}
                                  </p>
                                  {/* <span className="badge bg-danger float-end">
                                    1
                                  </span> */}
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <>No Members Found!</>
                    )}
                    {loader && <p className="text-center">loading...</p>}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-7 position-relative">
              {supportId ? (
                <>
                  <MessageList
                    supportId={supportId}
                    socket={socket}
                    chatListRefetch={refetch}
                    setSupportId={setSupportId}
                    supportDetails={supportDetails}
                    setSupportDetails={setSupportDetails}
                  />
                  {supportDetails?.status === 2 ? (
                    <p className="text-danger text-center">Chat Was Resolved</p>
                  ) : (
                    <SendMessageForm supportId={supportId} socket={socket} />
                  )}
                </>
              ) : (
                <div className=" d-flex justify-content-center align-items-center h-100">
                  <h5 className="text-white">
                    Please Select any Chat to start chatting .
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChatSupport;
