import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import { EVENT_LIST } from "services/ApiCalls";
import Plussvg from "../../../assets/svg/plussvg.svg";
import { truncateString } from "utilities/utilities";
import { useSelector } from "react-redux";

const Events = () => {
  const navigate = useNavigate();

  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: EVENT_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      status: 1,
      startDate: "",
      endDate: "",
    },
    dependencies: ["endDate"],
  });
  const permission = useSelector(
    (s) => s?.profileData?.eventPermission?.isView
  );

  const col = [
    {
      head: "Banner Image",
      accessor: "email",
      component: (item) => <TableProfile image={item?.bannerImage} />,
    },
    {
      head: "User Name",
      accessor: "",
      component: (item) => (
        <>{(item?.userId?.fname || "") + " " + (item?.userId?.lname || "")}</>
      ),
    },
    {
      head: "Address",
      accessor: "address",
      component: (item) => <>{truncateString(item?.address, 20)}</>,
    },
    {
      head: "Name",
      accessor: "",
      component: (item) => <>{item?.name}</>,
    },
    {
      head: "Description",
      accessor: "description",
      component: (item) => <>{truncateString(item?.description, 15)}</>,
    },
  ];
  if (permission != null ? permission : true) {
    col.push({
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions
            // editLink={`/edit-event/${item?._id}`}
            viewLink={`/view-event/${item?._id}`}
          />
        </>
      ),
    });
  }
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Events</h3>

              {/* <div className="toggle_side d-flex align-items-center gap-3">
                <h6>Enable from the App Side</h6>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div> */}
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-end gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />

                    <DatePickerField
                      label="Start Date"
                      placeholder="Start Date"
                      value={body?.startDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          startDate: date && moment(date).format("YYYY/MM/DD"),
                        }))
                      }
                    />
                    <DatePickerField
                      label="End Date"
                      placeholder="End Date"
                      value={body?.endDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          endDate: date && moment(date).format("YYYY/MM/DD"),
                          // endDate: date,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              {(permission != null ? permission : true) && (
                <div className="export_btn d-flex align-items-center gap-3">
                  <Button
                    className="plussvg"
                    onClick={() => navigate("/add-events")}
                  >
                    <img src={Plussvg} alt="" className="img-fluid" />
                  </Button>
                  {/* <Button className="export_csv">Export CSV</Button> */}
                </div>
              )}
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="pending"
                  checked={body?.status == 1}
                  onClick={() => setBody((p) => ({ ...p, status: 1 }))}
                />
                <label for="pending">Approved</label>

                <input
                  type="radio"
                  name="tab"
                  id="decline"
                  checked={body?.status == 2}
                  onClick={() => setBody((p) => ({ ...p, status: 2 }))}
                />
                <label for="decline">Decline</label>
              </div>
              <div className="manageuser_tabbingset mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default Events;
