import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Dashcards = ({dashData}) => {
  const navigate=useNavigate()
  return (
    <section className="dashcards mb-5 mt-2">
      <Row>
        <Col lg={3} md={6} sm={12} onClick={()=>navigate("/manage-users")} style={{cursor:"pointer"}}>
          <div className="common_box rounded-3 p-3">
            <h5>Total Users</h5>

            <div className="top_details d-flex align-items-center justify-content-between">
              <img src="" alt="" />
              <h3>{dashData?.totalUsersCount || 0}</h3>
            </div>

            {/* <div className="bottom_details d-flex align-items-center justify-content-between">
              <p> 
            
              </p>

              <p>

              </p>
            </div> */}
          </div>
        </Col>

        <Col lg={3} md={6} sm={12} onClick={()=>navigate("/posts")} style={{cursor:"pointer"}}>
          <div className="common_box rounded-3 p-3">
            <h5> Total Posts</h5>

            <div className="top_details d-flex align-items-center justify-content-between">
              <img src="" alt="" />
              <h3>{dashData?.totalPostsCount || 0}</h3>
            </div>

            {/* <div className="bottom_details d-flex align-items-center justify-content-between">
              <p>Completed Orders</p>

              <p>351</p>
            </div> */}
          </div>
        </Col>

        <Col lg={3} md={6} sm={12} onClick={()=>navigate("/events")} style={{cursor:"pointer"}}>
          <div className="common_box rounded-3 p-3">
            <h5>Total Events</h5>

            <div className="top_details d-flex align-items-center justify-content-between">
              <img src="" alt="" />
              <h3>{dashData?.totalEventsCount || 0}</h3>
            </div>

            {/* <div className="bottom_details d-flex align-items-center justify-content-between">
              <p>Completed Orders</p>

              <p>351</p>
            </div> */}
          </div>
        </Col>

        <Col lg={3} md={6} sm={12} onClick={()=>navigate("/pitches")} style={{cursor:"pointer"}}>
          <div className="common_box rounded-3 p-3">
            <h5>Total Pitches</h5>

            <div className="top_details d-flex align-items-center justify-content-between">
              <img src="" alt="" />
              <h3>{dashData?.totalPitchesCount || 0}</h3>
            </div>

            {/* <div className="bottom_details d-flex align-items-center justify-content-between">
              <p>Completed Orders</p>

              <p>351</p>
            </div> */}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Dashcards;
