import Fade from "react-reveal";
import { Form } from "reactstrap";

// images
import SearchFilter from "Component/common/SearchFilter";
import SortByFilter from "Component/common/SortByFilter";
import UserTypeFilter from "Component/common/UserTypeFilter";
import dataHandler from "hooks/dataHandler";
import { USERS_LIST } from "services/ApiCalls";
import Pendingtable from "./pendingtable";

const ManageChangeRoleUsers = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: USERS_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      accountType: "",
      userStatus: "pending",
      changeRoleRequest: "yes",
    },
    dependencies: ["accountType", "order"],
  });

  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Users Role Change Request</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <SearchFilter body={body} setBody={setBody} />
                    <UserTypeFilter body={body} setBody={setBody} />
                  </div>
                  <h5>Sort by :</h5>
                  <SortByFilter setBody={setBody} orderByKey="fname" />
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="pending_user_content mt-4">
                <Pendingtable
                  data={data}
                  total={total}
                  body={body}
                  setBody={setBody}
                  paginationHandler={paginationHandler}
                  loader={loader}
                  statusChangeHandler={statusChangeHandler}
                />
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default ManageChangeRoleUsers;
