import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Container, FormGroup } from "react-bootstrap";

import dummyImg from "../../assets/images/user.webp";
import { ADMIN_PROFILE, UPDATE_PROFILE } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";
import { FaCamera } from "react-icons/fa";
import fileUploader from "utilities/fileUploader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getProfile } from "store/Profile/actions";

const AdminProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [showpass, setshowpass] = useState(false);

  const eyetoggle = () => {
    setshowpass(!showpass);
  };

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    image: "",

  });
  const [error, setError] = useState("");

  const getData = catchAsync(async () => {
    const res = await ADMIN_PROFILE();
    checkResponse({
      res,
      setData: (data) => {
        console.log(data);
        setFormData({
          name: data?.name,
          email: data?.email,
          image: data?.image,
          _id: data?._id,
        });
        // setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onImageSend = async (e) => {
    console.log(e);
    const url = await fileUploader([e.target.files[0]]);
    if (url?.length !== 0) {
      setFormData((prevData) => ({
        ...prevData,
        image: url[0], // Store the selected item object directly in formData
      }));
    }
    console.log(url);
  };

  const handleSubmit = async () => {
    // Clear previous error
    setError("");

    // Validate the name field
    if (!formData.name.trim()) {
      setError("Name is required."); // Set error message if name is empty
      return;
    }
    if (!formData.image) {
      toast.error("Image is required."); // Show toast message for image
      return;
    }
    // If name is not empty, proceed with form submission
    try {
      // Your async submit logic here, e.g., an API call
      let res = await UPDATE_PROFILE(formData);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        dispatch(getProfile());
        setIsEditing(false);
        setIsShow((false))
        setFormData((prevData) => ({
          ...prevData,
          password:"", // Store the selected item object directly in formData
        }));
      } else {
        setIsEditing(false);
        setIsShow((false))

      }
      // Reset form or show success message as needed
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  return (
    <Container>
      <div className="form_Side profile_set">
        <form
          id="loginform"
          className="spinn-content forgot_password position-relative"
        >
          <div className="profile-picture-container  text-center login_heading">
            <div className="profile-picture-wrapper ">
              <img
                src={formData?.image ? formData?.image : dummyImg}
                alt="Profile"
                className="img-fluid profile-picture"
              />
              {isEditing && (
                <div className="overlay">
                  <label htmlFor="fileInput">
                    <FaCamera className="camera-icon" />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={onImageSend}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="profile-info-card">
            <h4>Profile Information</h4>
            {!isEditing ? (
              <div className="profile-details ">
                <p style={{ color: "white" }}>Name: {formData.name}</p>
                <p style={{ color: "white" }}>Email: {formData.email}</p>
                {/* <p style={{ color: "white" }}>Phone: {formData.phone}</p>
                <p style={{ color: "white" }}>Address: {formData.address}</p> */}
                <Button
                  onClick={() => setIsEditing(true)}
                  className="export_csv w-100"
                >
                  Edit Profile
                </Button>
              </div>
            ) : (
              <div className="author_form">
                <Row>
                  <Col>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        style={{ color: "white" }}
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {error && <p className="text-white">{error}</p>}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        readOnly
                        style={{ color: "white" }}
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="toggle_side d-flex align-items-center gap-3 mt-4">
                      <label class="switch">
                        <input
                          type="checkbox"
                          onChange={() => setIsShow(!isShow)}
                          checked={isShow}
                        />
                        <span class="slider round"></span>
                      </label>
                      <p className="text-white m-0">Change Password</p>
                    </div>
                  </Col>
                  {isShow && (
                    <Col lg={6}>
                      <FormGroup>
                        <Form.Label>Password</Form.Label>

                        <div className="subadmin_pass position-relative">
                          <Form.Control
                            type={showpass ? "text" : "password"}
                            style={{ color: "white" }}
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                          />
                          <Button
                            className="btn_eye"
                            type="button"
                            onClick={eyetoggle} // Use the toggle function
                          >
                            {showpass ? <>{eyeopen}</> : <>{eyeclose}</>}
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <Button
                  className="export_csv w-100 mt-4"
                  onClick={() => handleSubmit()}
                  type="button"
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </Container>
  );
};

export default AdminProfile;

const eyeopen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
    />
  </svg>
);

const eyeclose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"
    />
  </svg>
);
