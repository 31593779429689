import ImagePreviewModel from "Component/Modals/ImagePreveiwModel";
import dataHandler from "hooks/dataHandler";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { MANAGE_SUPPORT, MESSAGE_LIST } from "services/ApiCalls";
import {
  catchAsync,
  checkResponse,
  showMessageSendReceiveTime,
} from "utilities/utilities";
import dummyImg from "../../../assets/images/user.webp";

function MessageList({
  supportId,
  socket,
  chatListRefetch,
  supportDetails,
  setSupportDetails,
}) {
  const { body, data, setData, getData } = dataHandler({
    api: MESSAGE_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      supportId,
      page: 1,
      limit: 10000,
    },
  });

  const [resolveLoader, setResolveLoader] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const chatListContainer = useRef();

  const adminProfileImage =
    localStorage.getItem("profileImage") !== "undefined"
      ? localStorage.getItem("profileImage")
      : dummyImg;

  const scrollToBottom = () => {
    if (chatListContainer.current) {
      chatListContainer.current.scrollTop =
        chatListContainer.current.scrollHeight;
    }
  };

  useEffect(() => {
    getData({ ...body, supportId });
  }, [supportId]);

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket
        ?.off("receive-support-message")
        ?.on("receive-support-message", (message) => {
          console.log("message 1", message);

          chatListRefetch();

          if (message?.supportId === supportId) {
            setData((p) => [...p, message]);
            scrollToBottom();
          }
        });
    }
  }, [socket, supportId]);

  const ResolveHandler = catchAsync(
    async () => {
      setResolveLoader(true);
      const res = await MANAGE_SUPPORT({
        _id: supportId,
        status: 2,
      });
      const success = checkResponse({
        res,
        showSuccess: true,
        setLoader: setResolveLoader,
      });

      if (success) {
        setSupportDetails((p) => {
          if (p._id === supportId) {
            console.log(p, "check");

            const obj = {
              ...p,
            };
            obj.status = 2;
            return obj;
          } else {
            p;
          }
        });
      }
    },
    {
      setLoader: setResolveLoader,
    }
  );

  console.log(supportDetails, "supportDetails");

  return (
    <>
      <ImagePreviewModel
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
      />

      <div
        className="position-absolute "
        style={{
          right: 25,
          bottom: 70,
        }}
      >
        {/* arrow down  */}
        <FaArrowAltCircleDown size={30} onClick={scrollToBottom} />
      </div>

      <div className="d-flex justify-content-end mb-2">
        {supportDetails?.status == 2 ? (
          <Button disabled>Chat Was Resolved</Button>
        ) : (
          <Button onClick={ResolveHandler}>
            {resolveLoader ? "Resolving..." : "Resolve Chat"}
          </Button>
        )}
      </div>

      <ul
        className="list_scroll list-unstyled text-white position-relative "
        style={{
          height: "calc(100vh - 290px)",
          marginBottom: 70,
        }}
        ref={chatListContainer}
      >
        {data?.map((item) => {
          return (
            <li className="d-flex justify-content-between mb-4 gap-10">
              {item.senderId === item.adminId && (
                <img
                  src={adminProfileImage}
                  alt="avatar"
                  className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                  width={40}
                />
              )}
              <div className="card mask-custom">
                <div
                  className="card-header d-flex justify-content-between py-1"
                  style={{ borderBottom: "1px solid rgba(255,255,255,.3)" }}
                >
                  <p className="fw-bold mb-0">{item?.userDetails?.name}</p>
                  <p className="text-black custom_font mb-0 m-0">
                    {/* <i className="far fa-clock" />{" "} */}
                    {showMessageSendReceiveTime(item.createdAt)}
                  </p>
                </div>
                <div className="card-body p-2 ">
                  {item.messageType === 2 ? (
                    <img
                      onClick={() => setPreviewImage(item.message)}
                      src={item?.message}
                      style={{
                        width: 100,
                        width: 100,
                      }}
                    />
                  ) : (
                    <p className="mb-0 custom_font">{item?.message}</p>
                  )}
                </div>
              </div>
              {item?.senderId !== item?.adminId && (
                <img
                  src={item?.userId?.profileImage}
                  alt="avatar"
                  className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                  width={40}
                />
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default MessageList;
