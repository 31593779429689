import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Fade } from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { catchAsync, checkResponse } from "utilities/utilities";
import { ADD_SUB_ADMIN, SUB_ADMIN_DETAILS } from "services/ApiCalls";
import Loading from "Component/common/Loading";

// Define your validation schema
const schema = z.object({
  subname: z.string().min(1, "Name is required"),
  subemail: z.string().email("Invalid email").min(1, "Email is required"),
  subpassword: z.string().min(6, "Password must be at least 6 characters"),
  permissions: z.record(
    z.object({
      view: z.boolean().default(false),
      addEdit: z.boolean().default(false),
      delete: z.boolean().default(false),
    })
  ),
});

// Define your permission modules
const permissionModulesOption = [
  {
    sideBarId: 1, // Changed to string number
    text: "Pending Requests",
    isSubMenu: "true",
    subMenuItems: [
      { name: "Pending Users", view: false },
      { name: "Pending Events", view: false },
      { name: "Pending Pitches", view: false },
      { name: "Pending Posts", view: false },
      { name: "Change Role Request", view: false },
      { name: "Pending Chatroom", view: false },
    ],
  },
  {
    sideBarId: 2, // Changed to string number
    text: "Users",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 3, // Changed to string number
    text: "Pitches",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 4, // Changed to string number
    text: "Posts",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 5, // Changed to string number
    text: "Sub Admin",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 6, // Changed to string number
    text: "Customer Feedback",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 7, // Changed to string number
    text: "Events",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 8, // Changed to string number
    text: "Chatroom",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 9, // Changed to string number
    text: "Notification",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 10, // Changed to string number
    text: "Data & Analytics",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 11, // Changed to string number
    text: "Customer Support",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 12, // Changed to string number
    text: "Report User",
    isSubMenu: "false",
    subMenuItems: [],
  },
  {
    sideBarId: 13, // Changed to string number
    text: "CMS Pages",
    isSubMenu: "false",
    subMenuItems: [],
  },
];
const AddSubAdmin = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [permissionModules, setPermissionModules] = useState(
    permissionModulesOption
  );
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [showpass, setshowpass] = useState(false);

  const eyetoggle = () => {
    setshowpass(!showpass);
  };

  const { id } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset, // Use reset to set form values
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      permissions: {}, // Initialize with empty permissions
    },
  });

  const submitHandler = async (data) => {
    setSubmitLoader(true);

    const permissionArray = permissionModules.map((module) => {
      return {
        sideBarId: module.sideBarId,
        text: module.text,
        isSubMenu: module.isSubMenu.toString(),
        subMenuItems: module.subMenuItems.map((subItem) => ({
          name: subItem.name,
          isView:
            data.permissions[subItem.name.toLowerCase().replace(/\s+/g, "")]
              ?.view,
          isAdd:
            data.permissions[subItem.name.toLowerCase().replace(/\s+/g, "")]
              ?.addEdit,
          isDelete:
            data.permissions[subItem.name.toLowerCase().replace(/\s+/g, "")]
              ?.delete,
        })),
        isView:
          data.permissions[module.text.toLowerCase().replace(/\s+/g, "")]?.view,
        isAdd:
          data.permissions[module.text.toLowerCase().replace(/\s+/g, "")]
            ?.addEdit,
        isDelete:
          data.permissions[module.text.toLowerCase().replace(/\s+/g, "")]
            ?.delete,
      };
    });

    // Construct the request body
    const requestBody = id
      ? {
          _id: id,
          name: data.subname,
          email: data.subemail,
          password: data.subpassword,
          type: "PUBLISH", // Or whatever type is applicable
          permission: permissionArray,
        }
      : {
          name: data.subname,
          email: data.subemail,
          password: data.subpassword,
          type: "PUBLISH", // Or whatever type is applicable
          permission: permissionArray,
        };

    const res = await ADD_SUB_ADMIN(requestBody);

    checkResponse({
      res,
      showSuccess: true,
      navigateUrl: "/sub-admin",
      navigate,
      setLoader: setSubmitLoader,
    });
  };

  const getData = catchAsync(async () => {
    const res = await SUB_ADMIN_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        setData(data);
        setPermissionModules(data?.permission || permissionModulesOption);

        const permissions = data.permission.reduce((acc, module) => {
          acc[module.text.toLowerCase().replace(/\s+/g, "")] = {
            view: module.isView || false,
            addEdit: module.isAdd || false,
            delete: module.isDelete || false,
          };
          module.subMenuItems.forEach((subModule) => {
            acc[subModule.name.toLowerCase().replace(/\s+/g, "")] = {
              view: subModule.isView || false,
              addEdit: subModule.isAdd || false,
              delete: subModule.isDelete || false,
            };
          });
          return acc;
        }, {});

        reset({
          subname: data.name || "",
          subemail: data.email || "",
          subpassword: "", // Don't pre-fill password for security reasons
          permissions,
        });
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  console.log(data);
  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Add Sub-Admin</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/sub-admin">Manage Sub-Admin</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Add Sub-Admin</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form">
                <Row>
                  {/* Name Input */}
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="subname">Name</Label>
                      <Controller
                        name="subname"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="subname"
                            placeholder="Name"
                            {...field}
                            style={{ color: "white" }}
                          />
                        )}
                      />
                      {errors.subname && (
                        <span style={{ color: "red" }}>
                          {errors.subname.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  {/* Email Input */}
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="subemail">Email</Label>
                      <Controller
                        name="subemail"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="subemail"
                            placeholder="Email"
                            {...field}
                            style={{ color: "white" }}
                          />
                        )}
                      />
                      {errors.subemail && (
                        <span style={{ color: "red" }}>
                          {errors.subemail.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  {/* Password Input */}
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="subpassword">Password</Label>
                      <div className="subadmin_pass position-relative">
                        <Controller
                          name="subpassword"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type={showpass ? "text" : "password"} // Toggle password visibility
                              id="subpassword"
                              placeholder="Password"
                              {...field}
                              style={{ color: "white" }}
                            />
                          )}
                        />
                        <Button
                          className="btn_eye"
                          type="button"
                          onClick={eyetoggle} // Use the toggle function
                        >
                          {showpass ? <>{eyeopen}</> : <>{eyeclose}</>}
                        </Button>
                      </div>
                      {errors.subpassword && (
                        <span style={{ color: "red" }}>
                          {errors.subpassword.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  {/* Permissions Table */}

                  <Col lg={12} className="mt-3">
                    <div className="module_toggle">
                      <Table>
                        <thead>
                          <tr>
                            <th>Module</th>
                            <th>View</th>
                            <th>Add/Edit/Approve</th>
                            <th>Delete/Reject</th>
                          </tr>
                        </thead>
                        <tbody>
                          {permissionModules.map((module) => (
                            <React.Fragment key={module.sideBarId}>
                              {/* Module Row */}
                              <tr>
                                <td>{module.text}</td>
                                {["view", "addEdit", "delete"].map((action) => (
                                  <td key={action}>
                                    <div className="toggle_side">
                                      <Controller
                                        name={`permissions.${module.text
                                          .toLowerCase()
                                          .replace(/\s+/g, "")}.${action}`}
                                        control={control}
                                        render={({ field }) => (
                                          <label className="switch">
                                            {console.log("field", field.value)}
                                            <input
                                              type="checkbox"
                                              {...field}
                                              checked={
                                                field.value !== undefined
                                                  ? field.value
                                                  : true
                                              }
                                              onChange={(e) => {
                                                field.onChange(
                                                  e.target.checked
                                                ); // Update value correctly
                                              }}
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        )}
                                      />
                                    </div>
                                  </td>
                                ))}
                              </tr>

                              {/* Submodules Row */}
                              {module.subMenuItems.map((subModule) => (
                                <tr key={subModule.name}>
                                  <td style={{ paddingLeft: "20px" }}>
                                    {subModule.name}
                                  </td>
                                  {["view", "addEdit", "delete"].map(
                                    (action) => (
                                      <td key={action}>
                                        <div className="toggle_side">
                                          <Controller
                                            name={`permissions.${subModule.name
                                              .toLowerCase()
                                              .replace(/\s+/g, "")}.${action}`}
                                            control={control}
                                            render={({ field }) => (
                                              <label className="switch">
                                                <input
                                                  type="checkbox"
                                                  {...field}
                                                  checked={
                                                    field.value !== undefined
                                                      ? field.value
                                                      : true
                                                  }
                                                  onChange={({
                                                    target: { checked },
                                                  }) => field.onChange(checked)}
                                                />
                                                <span className="slider round"></span>
                                              </label>
                                            )}
                                          />
                                        </div>
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Col lg={12} className="mt-4">
                  <Button className="export_csv w-100" style={{ height: 50 }}>
                    {submitLoader ? <Loading fullSize={true} /> : "Submit"}
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </form>
      </section>
    </Fade>
  );
};

export default AddSubAdmin;

const eyeopen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
    />
  </svg>
);

const eyeclose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"
    />
  </svg>
);
